import { formatPrice } from 'src/utilities/helpers';

export class Product {
  constructor(product) {
    if (product === undefined) {
      this.setEmptyProduct();
    } else {
      this.setProduct(product);
      if (this.images) {
        this.images.unshift(this.imageUrl);
      } else {
        this.images = [this.imageUrl];
      }
    }
  }

  setProduct(product) {
    this.id = product.id;
    this.shortDescription = product.shortDescription;
    this.subTitle = product.subTitle;
    this.longDescription = product.longDescription;
    this.properties = product.properties;
    this.targetUrl = product.targetUrl;
    this.imageUrl = product.imageUrl;
    this.images = product.images;
    this.brand = product.brand;
    this.variantKey = product.variantKey;
    this.units = product.units;
    // BESPOKE TUIN-80
    // Set bespoke saleunit to take precedent over default saleunit
    this.saleUnit = this.setSaleUnit(product.saleUnit, product.customDecimals.SALE_UNIT_BESPOKE);
    // END BESPOKE TUIN-80
    this.discountGroup = product.discountGroup;
    this.prices = [];
    this.units = this.setUnits(product.units);
    this.stock = { stockTotal: null };
    this.variantKey = product.variantKey;
    this.productVariants = [];
    this.customStrings = product.customStrings;
    this.customDecimals = product.customDecimals;
    this.customBooleans = product.customBooleans;
    this.customDateTimes = product.customDateTimes;
    this.includedInCustomerLists = [];
    this.imageAltText = product.id;
    this.imageTitleText = product.id;
    this.productImageBanners = [
      { position: 'top-left', values: [], show: false },
      { position: 'top-right', values: [], show: false },
      { position: 'bottom-right', values: [], show: false },
      { position: 'bottom-left', values: [], show: false }
    ];
    // BESPOKE TUIN
    this.statistics = null;
    // END BESPOKE TUIN
  }

  setStatistics(statistics) {
    if (statistics) {
      this.statistics = statistics;
    } else {
      this.statistics = {
        TotaalA: 0,
        TotaalB: 0,
        TotaalC: 0
      };
    }
  }

  setEmptyProduct() {
    this.id = 'NOTLOADED';
    this.shortDescription = '';
    this.subTitle = '';
    this.longDescription = '';
    this.properties = '';
    this.targetUrl = '';
    this.imageUrl = '';
    this.images = [];
    this.brand = '';
    this.variantKey = '';
    this.units = [];
    this.saleUnit = '';
    this.discountGroup = '';
    this.prices = [];
    this.units = null;
    this.stock = { stockTotal: null };
    this.variantKey = '';
    this.productVariants = [];
    this.customStrings = {};
    this.customDecimals = {};
    this.customBooleans = {};
    this.customDateTimes = {};
    this.includedInCustomerLists = [];
    this.imageAltText = '';
    this.imageTitleText = '';
  }


  setStock (stock) {
    this.stock = stock;
  }

  setSeoInformation (seoInfo) {
    this.imageAltText = seoInfo.ImageAltText;
    this.imageTitleText = seoInfo.ImageTitleText;    
  }

  setProductImageBanners(banners) {
    banners.forEach(banner => {
      this.productImageBanners[banner.Location].values.push(banner);
      this.productImageBanners[banner.Location].show = true;
    });
  }

  setUnits (units) {
    if (units) {
      return units.sort((a, b) => (a.quantity > b.quantity) ? 1 : -1)
    } else return null;
  }

  setProductVariants (productVariants) {
    this.productVariants = productVariants;
  }

  setCustomerLists (customerList) {
    this.includedInCustomerLists = customerList;
  }

  setPrices (prices) {
    if (prices !== undefined) {
      prices.forEach(priceObj => {
        priceObj.rawPrice = priceObj.price;
        priceObj.rawBasePrice = priceObj.basePrice;
        priceObj.price = formatPrice(priceObj.price);
        priceObj.basePrice = formatPrice(priceObj.basePrice);
        priceObj.quantity = priceObj.quantity === 0 ? 1 : priceObj.quantity;

        // BESPOKE TUIN
        // Set prices incl vat
        priceObj.priceIncl = formatPrice(priceObj.rawPrice * (1 + this.customDecimals.VAT_PERCENTAGE / 100));
        priceObj.basePriceIncl = formatPrice(priceObj.rawBasePrice * (1 + this.customDecimals.VAT_PERCENTAGE / 100));
        // END BESPOKE TUIN

        // Add prices sales banner to product image banners
        if (priceObj.isSalesAction) {
          const salesBanner = window.vuexStore.$store.getters.saleBannerColors;
          this.productImageBanners[0].show = true;

          this.productImageBanners[0].values.unshift({
            Text: salesBanner.text,
            Color: salesBanner.color,
            TextColor: salesBanner.textColor,
            Location: 0,
            Type: 0
          });
        }        
      });
    }
    this.prices = prices;
  }

  // BESPOKE TUIN-80
  // Some products have a bespoke sale_unit, which takes precedence over the normal sale_unit  
  setSaleUnit (saleUnit, bespokeSaleUnit) {
    return bespokeSaleUnit > 1 ? bespokeSaleUnit : saleUnit;
  }
  // END BESPOKE TUIN-80
}
