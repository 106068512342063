<template>
<div class="cmp-statistics-table">
  <div class="header-row" v-if="showHeader">
    <div class="turnover-label totals-col empty-cell"></div>
    <div class="totals-c totals-col">
      {{ new Date().getFullYear() -2 }}
    </div>
    <div class="totals-b totals-col">
      {{ new Date().getFullYear() - 1 }}
    </div>
    <div class="totals-a totals-col">
        {{ new Date().getFullYear() }}
    </div>
  </div>

  <div class="content-row">
    <template v-if="!statistics">
      <div class="totals-col"></div>
      <div class="totals-col"></div>
      <div class="totals-col"></div>
    </template>
    <template v-else>
      <div class="turnover-label totals-col" v-if="showTurnoverLabel">
        <span v-translation="{ type: 'label', code: 'label_yearly_turnover' }"></span>
      </div>
      <div class="totals-c totals-col col-value">
        {{ statistics.TotaalA }}
      </div>
      <div class="totals-b totals-col col-value">
        {{ statistics.TotaalB }}
      </div>
      <div class="totals-c totals-col col-value">
        {{ statistics.TotaalC }}
      </div>
    </template>
    
  </div>
</div>
</template>

<script>
export default {
  props: {
    statistics: { required: true },
    showHeader: { type: Boolean, required: false, default: false },
    showTurnoverLabel: { type: Boolean, required: false, default: false }
  }
}
</script>
