<template>
  <div class="cmp-shopping-cart-overview utlz-reset utlz-row" :class="tableClasses">
    
    <!-- BESPOKE TUIN -->
    <span id="scroll-anchor-top"></span>
    <!-- END BESPOKE TUIN -->

    <transition name="fadein">
      <utlz-prompt
        v-if="showDeleteProductPrompt"
        :payload="promptPayload.lineId"
        @confirm="deleteOrderLineFromCart($event)"
        @cancel="cancelPrompt()"
      >

      <template v-slot:prompt-content>
        <div class="delete-product-prompt utlz-row">
          <div class="utlz-col-12">
            <h2>
              <span v-translation="{ type: 'title', code: 'title_prompt_delete_from_cart' }"></span>
            </h2>
          </div>
          <div class="utlz-col-3 img">
            <img :src="promptPayload.product.imageUrl">
          </div>
          <div class="utlz-col-9">
            <h3>
              {{ promptPayload.product.shortDescription }}
            </h3>
          </div>
        </div>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-prompt
        v-if="showEmptyCartPrompt"
        @confirm="emptyCart()"
        @cancel="cancelEmptyCartPrompt()"
      >

      <template v-slot:prompt-content>
        <h2 v-translation="{ type: 'text', code: 'txt_confirm_clear_shopping' }"></h2>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-static-modal v-if="validatingStock">
        <template v-slot:static-modal-content>
          <div class="validate-stock-modal utlz-row">
            <div class="utlz-col-12 flex-row-nowrap align-center just-center">
              <span class="loader"></span>
              <h2>
                <span v-translation="{ type: 'label', code: 'label_validating_stock' }"></span>
              </h2>
            </div>
          </div>
        </template>
      </utlz-static-modal>
    </transition>

    <transition-group name="fade" tag="div" class="ph-wrapper utlz-col-12">
      <template v-if="loadingOrderLines">
        <div v-for="index in 4" :key="index" class="ph-silhouette orderlist-row">
          <span class="silhouette-img"></span>
          <div class="ph-paragraph">
            <span class="silhouette-line"></span>
            <span class="silhouette-line"></span>
          </div>
        </div>
      </template>
    </transition-group>

    <div v-if="shoppingCartEmpty" class="empty-cart-message col-md-12">
      <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_empty' }"></h2>
    </div>
    
    <!-- BESPOKE TUIN-106 -->
    <template v-if="showEmptyShoppingCart && !shoppingCartEmpty && !loadingOrderLines">
      <div class="utlz-col-md-12 clear-cart-col top-btn">
        <a @click="showEmptyCartPrompt = true" v-translation="{ type: 'button', code: 'btn_clear_shopping_cart' }" class="button small outline clear-cart"></a>
        <!-- BESPOKE GS-580: Add button to scroll to bottom of shoppingcart lines-->
        <div id="scroll-arrow-anchor-top" class="utlz-col-md-12 scroll-anchor" v-if="showScrollButtons && !loadingOrderLines">
          <a @click="scrollToBottom()" class="scroll-btn top">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </a>
        </div>
        <!-- END BESPOKE GS-580 -->
      </div>
    </template>
    <!-- END BESPOKE TUIN-106 -->    

    <transition name="fade">
      <!-- BESPOKE TUIN-106: Added :class binding to make element sticky with css -->
      <div :class="{ 'sticky': setSticky }" v-if="showOrderLines && screenWidth >= 880" class="orderline-header utlz-col-md-12">
        <!-- END BESPOKE TUIN-106 -->
        <div class="orderlines-wrapper">
          <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell"></div>
          <div class="prod-desc orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_desc' }"></span>
          </div>
          <div v-if="showPrices" class="prod-price orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_price' }"></span>
          </div>

          <div class="prod-stock orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_stock' }"></span>
          </div>

          <div class="order-product orderline-cell">
            <!-- BESPOKE TUIN-106 -->
            <span class="sale-unit-header" v-translation="{ type: 'label', code: 'label_sale_unit'}"></span>
            <!-- END BESPOKE TUIN-106 -->
            <span v-translation="{ type: 'label', code: 'label_header_prod_quantity' }"></span>
          </div>
          <div v-if="showPrices" class="prod-total orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_total' }"></span>
          </div>

          <!-- BESPOKE TUIN-->
          <div class="stats-col">
            <div class="content-row">
              <div class="totals-col">
                <span v-translation="{ type: 'label', code: 'label_yearly_turnover' }"></span>
              </div>
              <div class="totals-c totals-col">
                {{ new Date().getFullYear() -2 }}
              </div>
              <div class="totals-b totals-col">
                {{ new Date().getFullYear() - 1 }}
              </div>
              <div class="totals-a totals-col">
                  {{ new Date().getFullYear() }}
              </div>
            </div>
          </div>
          <!-- END BESPOKE TUIN -->

          <div class="delete-prod orderline-cell">
          </div>
        </div>
      </div>
    </transition>

    <transition-group name="fade" tag="div" class="orderline-table utlz-col-md-12">
      <template v-if="showOrderLines">
        <div v-for="orderLine in orderLines" :key="orderLine.lineId" class="orderline-row">

          <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell">
            <a :href="orderLine.product.targetUrl">
              <img :src="orderLine.product.imageUrl" />
            </a>
          </div>

          <div class="prod-desc orderline-cell">
            <a :href="orderLine.product.targetUrl">
              <h3 class="prod-title body-text">{{ orderLine.product.shortDescription }}</h3>
              <span class="prod-code">{{ orderLine.product.id }}</span>
            </a>

            <template v-if="useOrderLineComments && screenWidth >= 880">
              <utlz-order-line-comment
                :orderLine="orderLine"
                ></utlz-order-line-comment>
            </template>
          </div>

          <template v-if="showPrices">
            <div class="prod-price orderline-cell" v-if="orderLine.product.prices.length">
              <utlz-product-price
                :prices="orderLine.totals"
              ></utlz-product-price>
            </div>
            <div v-else class="stock-status-wrapper text-placeholder pulse">
              <span class="loader"></span>
            </div>
          </template>

          <div class="prod-stock orderline-cell">
            <utlz-product-stock
              :stock="orderLine.product.stock"
              :stockTotal="orderLine.product.stock"
            ></utlz-product-stock>
          </div>

          <div class="order-product orderline-cell">
            <span v-if="loading" class="loader"></span>
            <utlz-order-product
              v-if="orderLine.product.stock !== null"
              :product="orderLine.product"
              :initialQuantity="orderLine.quantity"
              :initialUnitCode="orderLine.unitCode"
              :useOrderMode="false"
              :totalOrderLinesQuantity="orderLine.totalOrderLinesQuantity"
              @quantityChanged="quantityChanged($event, orderLine)"
              @unitsChanged="unitsChanged($event, orderLine)"
            ></utlz-order-product>
          </div>

          <div class="prod-total orderline-cell">
            <span>{{ orderLine.totals.totalPrice }}</span>
          </div>

          <!-- BESPOKE TUIN -->
          <div class="stats-col">
            <utlz-statistics-table 
              :showTurnoverLabel="screenWidth < 880" 
              :showHeader="screenWidth < 880" 
              :statistics="orderLine.product.statistics"></utlz-statistics-table>
          </div>
          <!-- END BESPOKE TUIN -->

          <div class="delete-prod orderline-cell">
            <span class="close-button small grey" @click="promptDelete(orderLine)">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </span>
          </div>

          <template v-if="useOrderLineComments && screenWidth < 880">
            <div class="mobile-order-comment">
              <utlz-order-line-comment
                :orderLine="orderLine"
              ></utlz-order-line-comment>
            </div>
          </template>

        </div>
      </template>

    </transition-group>

    <!-- BESPOKE TUIN -->
    <span class="utlz-col-12" id="scroll-anchor-bottom"></span>
    <!-- END BESPOKE TUIN -->    

    <template v-if="showEmptyShoppingCart && !shoppingCartEmpty && !loadingOrderLines">
      <div class="utlz-col-md-12 clear-cart-col">
        <a @click="showEmptyCartPrompt = true" v-translation="{ type: 'button', code: 'btn_clear_shopping_cart' }" class="button small outline clear-cart"></a>
        <!-- BESPOKE TUIN-106: Add button to scroll to bottom of shoppingcart lines-->
        <div id="scroll-arrow-anchor-bottom" class="scroll-anchor utlz-col-md-12" v-if="showScrollButtons && !loadingOrderLines">
          <a @click="scrollToTop()" class="scroll-btn bottom">
            <i class="fa fa-angle-up" aria-hidden="true"></i>
          </a>
        </div>
        <!-- END BESPOKE TUIN-106 -->
      </div>
    </template>

    <div id="stock-warning" class="utlz-col-md-6">
      <transition name="fade">
        <template v-if="invalidOrderLines.length">
          <div class="stock-warning">
            <div class="stock-warning-wrapper">
              <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_stock_warning' }"></h2>
              <ul>
                <li v-for="orderLine in invalidOrderLines" :key="orderLine.lineId">
                  <i class="fa fa-caret-right" aria-hidden="true"></i><span>{{ orderLine.product.shortDescription }}</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </transition>

      <utlz-coupons v-if="useCoupons && showOrderLines"></utlz-coupons>

      <!-- BESPOKE TUIN: Added shipping and payment conditions -->
      <div class="terms-conditions" v-if="showOrderLines">
        <div class="row">
            <div class="col-md-12">
              <h3 v-translation="{ type: 'label', code: 'label_your_payment_conditions'}"></h3>
            </div>
        </div>
        <div class="row margin-bottom-20">
            <div class="col-md-12">
              <span>{{ paymentConditions }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">              
              <h3 v-translation="{ type: 'label', code: 'label_your_delivery_conditions'}"></h3>
            </div>
        </div>
        <div class="row margin-bottom-20">
            <div class="col-md-12">
              <span>{{ shipmentConditions }}</span>
            </div>
        </div>
      </div>
      <!-- END BESPOKE TUIN -->

    </div>

    <div class="utlz-col-md-6">
      <transition name="fade">
        <utlz-shopping-cart-totals
          v-if="showOrderLines"
          :format="shoppingCartTotalsFormat"
        ></utlz-shopping-cart-totals>
      </transition>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Prompt from 'src/components/ui/prompt/Prompt.vue';
import StaticModal from 'src/components/ui/static-modal/StaticModal.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import Coupons from 'src/components/webshop/coupons/Coupons.vue';
import OrderLineComment from 'src/components/webshop/order-product/blocks/OrderLineComment.vue';
import ShoppingCartTotals from 'src/components/webshop/shopping-cart/ShoppingCartTotals.vue';
import _ from 'lodash';

// BESPOKE
import StatisticsTable from "./../../../components/statistics/StatisticsTable.vue";
// END BESPOKE

export default {
  components: {
    'utlz-prompt': Prompt,
    'utlz-static-modal': StaticModal,
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-coupons': Coupons,
    'utlz-order-line-comment': OrderLineComment,
    'utlz-shopping-cart-totals': ShoppingCartTotals,
    // BESPOKE
    'utlz-statistics-table': StatisticsTable
    // END BESPOKE
  },
  data () {
    return {
      loading: false,
      showDeleteProductPrompt: false,
      promptPayload: null,
      showEmptyCartPrompt: false,
      // BESPOKE TUIN      
      setSticky: false,
      showScrollButtons: false
      // END BESPOKE TUIN
    }
  },
  computed: {
    ...mapState(['bespokeUserInformation']),
    ...mapGetters(['screenWidth', 'showStock', 'useCoupons', 'useOrderLineComments', 'useMinimumOrderQuantity', 'hideShoppingCartImages', 'showEmptyShoppingCart', 'showPrices']),
    ...mapGetters('shoppingCart', ['orderLines', 'invalidOrderLines', 'loadingOrderLines', 'orderLinesTotal', 'minimumOrderQuantity', 'shoppingCartEmpty', 'shoppingCartTotalsFormat', 'validatingStock']),
    showOrderLines () {
      return this.orderLines.length > 0 && this.orderLines[0].product !== null;
    },
    tableClasses () {
      let classes = '';
      if (!this.showStock) {
        classes += 'hide-stock ';
      }
      if (this.hideShoppingCartImages) {
        classes += 'hide-images ';
      }
      return classes;
    },
    shipmentConditions () {
      return window.vue.globalData.userInformation.bespokeUserInformation.shipmentCond;
    },
    paymentConditions () {
      return window.vue.globalData.userInformation.bespokeUserInformation.paymentCond;
    }
  },
  methods: {
    ...mapActions('shoppingCart', ['initShoppingCart', 'updateOrderLineQuantity', 'deleteOrderLine', 'emptyShoppingCart']),
    quantityChanged ({ computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: orderLine.unitCode,
        IsFree: orderLine.isFree
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    unitsChanged ({ unitCode, quantity, computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: unitCode,
        IsFree: orderLine.isFree
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    promptDelete (orderLine) {
      this.showDeleteProductPrompt = true;
      this.promptPayload = orderLine;
    },
    cancelPrompt () {
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    deleteOrderLineFromCart (orderLineId) {
      this.deleteOrderLine(orderLineId);
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    cancelEmptyCartPrompt () {
      this.showEmptyCartPrompt = false;
    },
    emptyCart () {
      this.showEmptyCartPrompt = false;
      this.emptyShoppingCart();
    },
    // BESPOKE TUIN-106
    scrollToTop () {
      document.querySelector('#scroll-anchor-top').scrollIntoView({ 
        behavior: 'smooth',
        block: 'center' 
      });
    },
    scrollToBottom () {
      document.querySelector('#scroll-anchor-bottom').scrollIntoView({ 
        behavior: 'smooth',
        block: 'center'
      });
    }
    // END BESPOKE TUIN-106
  },
  // BESPOKE
  updated() {
    let orderLinesTableHeight = $('.orderline-table').height();
    let windowHeight = $(window).height();
    this.showScrollButtons = orderLinesTableHeight > windowHeight;
  },
  // END BESPOKE
  created () {
    this.initShoppingCart();
    this.quantityChanged = _.debounce(this.quantityChanged, 500);
    
    // BESPOKE TUIN-106    
    document.addEventListener('scroll', ()=> {
      let pastBottom = document.querySelector("#scroll-anchor-bottom").getBoundingClientRect().top - 105 < 0;
      if (pastBottom) {
        this.setSticky = false;
      } else {        
        this.setSticky = document.querySelector("#scroll-anchor-top").getBoundingClientRect().top - 150 < 0;       
      }
    });

    $(window).scroll(()=> {    
      if (this.showScrollButtons) {
        let scrollButton = document.querySelector('.scroll-btn.bottom');
        let scrollButtonInView = false;
        if (scrollButton !== null) {
          scrollButtonInView = scrollButton.getBoundingClientRect().top > 0;
          if (scrollButtonInView) {
            $('#topcontrol').addClass('hidden');
          } else {
            $('#topcontrol').removeClass('hidden');
          }
        }
      } else {
        $('#topcontrol').removeClass('hidden');
      }
    });
    // END BESPOKE TUIN-106
  }
}
</script>

<style>

</style>
