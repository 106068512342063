import { formatPrice } from 'src/utilities/helpers';

export class OrderLine {
  constructor (orderLine) {
    this.product = null;
    this.shoppingCartId = orderLine.ShoppingCartId;
    this.productId = orderLine.ProductId;
    this.quantity = parseInt(orderLine.Quantity);
    this.computedQuantity = null;
    this.totalOrderLinesQuantity = orderLine.TotalCount;
    this.rawQuantity = parseInt(orderLine.Quantity);
    this.unitCode = orderLine.UnitCode;
    this.isFree = orderLine.IsFree;
    this.lineId = orderLine.LineId;
    this.comments = orderLine.Comments;
    this.customFields = orderLine.CustomFields;
    this.exceedsStock = false;
    this.totals = {
      price: '',
      rawPrice: 0
    };
  }

  setProduct (product) {
    this.product = product;
  }

  getComputedQuantity () {
    let quantity = this.quantity;
    if (this.product.units) {
      quantity = this.product.units.filter(unit => unit.code === this.unitCode)[0].quantity * this.quantity;
    } else if (this.product.saleUnit > 1) {
      // BESPOKE TUIN
      // Only multiply quantity times saleunit if the product does not use a bespoke saleunit
      // since when a product has a bespoke saleunit we send back the computed quantity to the backend 
      if (this.product.customDecimals.SALE_UNIT_BESPOKE === 0) {
        quantity = this.product.saleUnit * this.quantity;
      }
      // END BESPOKE TUIN
    }
    return quantity;
  }

  setComputedQuantity () {
    this.computedQuantity = this.getComputedQuantity();
  }

  setOrderLineTotal () {
    let price, totals;
    const quantity = this.getComputedQuantity();

    let volumeIndex = 0;

    this.product.prices.forEach((price, index) => {
      if (quantity >= price.quantity) {
        volumeIndex = index;
      }
    });

    price = this.product.prices[volumeIndex].rawPrice;
    totals = price * quantity;

    // BESPOKE TUIN
    // Set prices incl vat
    var priceIncl = price * (1 + this.product.customDecimals.VAT_PERCENTAGE / 100);
    var totalIncl = priceIncl * quantity;
    // END BESPOKE TUIN

    this.totals = {
      price: formatPrice(price),
      rawPrice: price,
      totalPrice: formatPrice(totals),
      totalRawPrice: totals,
      isSalesAction: this.product.prices[volumeIndex].isSalesAction,
      // BESPOKE TUIN
      // Added prices incl VAT
      priceIncl: formatPrice(priceIncl),
      totalPriceIncl: formatPrice(totalIncl)
      // END BESPOKE TUIN
    };
  }
}
