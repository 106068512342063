<template>
  <div class="cmp-quantity-btns">
    <div class="quantity-btns-wrapper flex-row-nowrap align-center">
      <a class="quantity-btn button square small decrement fa fa-minus" @click="decrement"></a>
      <input class="quantity-fld" type="number" min="0" v-model="quantity" @change="manualQuantity">
      <a class="quantity-btn button square small increment fa fa-plus" @click="increment"></a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    initialQuantity: { type: Number, required: false, default: 1 },
    quantityFactor: { type: Number, required: false, default: 1 },
    allowZeroQuantity: { type: Boolean, required: false, default: false },
    // BESPOKE
    editSaleUnit: { type: Boolean, required: false, default: false }
    // END BESPOKE
  },
  data () {
    return {
      quantity: this.initialQuantity,
      // BESPOKE TUIN-80
      // Added computedQuantityFactor
      computedQuantityFactor: this.quantityFactor
      // END BESPOKE TUIN-80
    }
  },
  watch: {
    initialQuantity () {
      this.quantity = this.initialQuantity;
    }
  },
  methods: {
    quantityChanged () {
      this.quantity = parseInt(this.quantity);
      this.$emit('quantityChanged', { quantity: this.quantity });
    },
    decrement () {
      // BESPOKE TUIN-80: Changed this.quantityFactor to this.computedQuantityFactor
      if (this.quantity <= this.computedQuantityFactor) {
        if (this.allowZeroQuantity) {
          this.quantity = 0;
          this.quantityChanged();
        } else {
          this.quantity = this.computedQuantityFactor;
        }
      } else {
        this.quantity -= this.computedQuantityFactor;
        this.quantityChanged();
      }
      // END BESPOKE TUIN-80
    },
    increment () {
      // BESPOKE TUIN-80: Changed this.quantityFactor to this.computedQuantityFactor
      this.quantity += this.computedQuantityFactor;
      // END BESPOKE TUIN-80
      this.quantityChanged();
    },
    manualQuantity () {
      // BESPOKE TUIN-80
      // Changed this.quantityFactor to this.computedQuantityFactor
      // Added this.editSaleUnit check to set the factor to 1 if it has been manually changed by the user
      // since the bespoke saleunit is in this case a recommendation for the quantity factor, but not the actual facto
      // e.g. factor is 5, so quantity button increment by 5, but the user may change input to 3 manually
      if (this.editSaleUnit) {        
        this.computedQuantityFactor = 1;
      }
      if (Math.sign(this.quantity) <= 0 || this.quantity === '') {
        if (this.editSaleUnit) {
          this.quantity = 1;
        } else {
          this.quantity = this.computedQuantityFactor;
        }
      } else if (this.quantity % this.quantityFactor !== 0) {
        if (!this.editSaleUnit) {
          this.quantity = Math.ceil(this.quantity / this.quantityFactor) * this.quantityFactor;
        } 
      }
      // END BESPOKE TUIN-80
      this.quantityChanged();
    }
  }
}
</script>

<style>

</style>
